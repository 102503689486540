import logo from './bg1.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={logo} className="back-image" alt="Botaniku - rooftop bar" />
    </div>
  );
}

export default App;
